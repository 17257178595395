import React from "react"
import cx from "classnames"
import RichTextResolver from "../../helpers/storyblok/richTextResolver"
import Image from "../../components/ui/image/image"

//Styles
import "./results-usp-variant-two.scss"

const ResultsUspVariantTwo = props => {
  const componentProps = props.blok ? props.blok : props.prop

  return (
    <div
      className={cx("results-usp-variant-two", {
        "padding-top": componentProps.paddingTop,
      })}
    >
      <div className="results-usp-variant-two__content">
        <div className="results-usp-variant-two__content--image_container">
          <Image
            largeScreenImage={componentProps.image_before}
            largeScreenHeight={800}
            smallScreenImage={componentProps.image_before}
            smallScreenHeight={800}
            alt="Tanrevel Before"
            layout="constrained"
          />
          <Image
            largeScreenImage={componentProps.image_after}
            largeScreenHeight={800}
            smallScreenImage={componentProps.image_after}
            smallScreenHeight={800}
            alt="Tanrevel After"
            layout="constrained"
          />
        </div>
        <div className="results-usp-variant-two__content--usp-box">
          <div className="results-usp-variant-two__content--usp-box--inner">
            <p className="results-usp-variant-two__content--usp-box--header">
              {componentProps.title}
            </p>
            <div className="results-usp-variant-two__content--usp">
              {componentProps.benefits.map((item, index) => (
                <div
                  key={index}
                  className="results-usp-variant-two__content--usp-item"
                >
                  <div className="results-usp-variant-two__content--usp-item--box">
                    <h4>{item.heading}</h4>
                    <RichTextResolver data={item.description} />
                  </div>
                  <Image
                    largeScreenImage={item.asset.filename}
                    smallScreenImage={item.asset.filename}
                    alt={item.asset.alt}
                    layout="fixed"
                    largeScreenHeight={32}
                    smallScreenHeight={20}
                    loading="eager"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResultsUspVariantTwo
